import React from 'react';

import Container from 'components/ui/Container';

import * as Styled from './styles';

const Footer: React.FC = () => (
	<Styled.Footer>
		<Container>
			<Styled.Links>
				<Styled.Link href="https://nina-ruben.de">© 2003 Bei Nina und Ruben.</Styled.Link>
			</Styled.Links>
		</Container>
	</Styled.Footer>
);

export default Footer;
