import React, { useState } from 'react';

import * as Styled from './styles';

interface MainNavItem {
	title: string;
	slug: string;
	icon?: string;
}

const mainNavItems: MainNavItem[] = [
	{
		title: 'Startseite',
		slug: '/',
		icon: '🏠'
	},
	{
		title: 'Hochzeitsodysee',
		slug: '/odysee/',
		icon: '🚌'
	},
	{
		title: 'Hochzeitsbilder',
		slug: '/hochzeitsbilder/',
		icon: '💑'
	},
	{
		title: 'Kinderwunsch',
		slug: '/kinderwunsch/',
		icon: '👶'
	},
	{
		title: 'Kontakt',
		slug: '/contact/'
	}
];

const MainNav: React.FC = () => {
	const [open, setOpen] = useState(false);

	return (
		<>
			<Styled.MainNav open={open}>
				{mainNavItems.map((item, index) => (
					<Styled.MainNavItem key={`nav-item-${index}`} to={item.slug} activeClassName="active">
						{item.icon && item.icon} {item.title}
					</Styled.MainNavItem>
				))}
			</Styled.MainNav>
			<Styled.ToogleMainNav aria-label="burgermenu" open={open} onClick={() => setOpen(!open)}>
				<span />
				<span />
				<span />
			</Styled.ToogleMainNav>
		</>
	);
};

export default MainNav;
