import React from 'react';

import MainNav from './MainNav';

import * as Styled from './styles';

interface Props {
	siteTitle: string;
	subTitle: string;
}

const Header: React.FC<Props> = () => (
	<Styled.Header>
		<Styled.Wrapper>
			<MainNav />
		</Styled.Wrapper>
	</Styled.Header>
);

export default Header;
